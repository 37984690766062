<template>
  <ion-page>
    <ion-header translucent>
      <ion-toolbar :color="detColor">
        <ion-buttons slot="start">
          <ion-back-button :text="detTitle" defaultHref="/tabs/eingabe"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar :color="detColor">
        <ion-searchbar color="light" ref="searchbar" @ionChange="onInputChange" placeholder="Taxon auswählen.." show-clear-button="always"></ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-text>Bitte geben Sie nach Möglichkeit den wissenschaftlichen Taxonnamen ein. Gattung und Artepitheton können abgekürzt werden (z. B. Trif rep für Trifolium repens). Die Trefferliste ist auf 30 Taxa beschränkt.</ion-text>
      <ion-item v-for="(option, key) in filteredOptions" :key="key" @click="onSelected(option)" class="ion-activatable ripple-parent">
        {{option.Art}}
        <ion-ripple-effect></ion-ripple-effect>
      </ion-item>
    </ion-content>
  </ion-page>
</template>

<script>
import { CONFIG } from '../helpers/config.js'
import storeMixin from '../mixins/store.js'
import utilsMixin from '../mixins/utils.js'
import { useRoute } from 'vue-router'
import { 
  IonSearchbar,
  IonPage,
  IonContent,
  IonItem,
  IonRippleEffect,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonText
} from '@ionic/vue'
let db
export default {
  components: {
    IonPage,
    IonContent,
    IonSearchbar,
    IonItem,
    IonRippleEffect,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonText
  },
  inject: ['storage'],
  mixins: [storeMixin, utilsMixin],
  data () {
    return {
      route: useRoute(),
      sample: {},
      occ: useRoute().params.occ,
      filteredOptions: [],
    }
  },
  computed: {
    detColor: function () {
      if (this.occ=="new")
        return "primary"
      else if (this.occ=="subsample")
        return "secondary"
      else if (this.sample.type=="subplot")
        return "secondary"
      else
        return "primary"
  },
    detTitle: function () {
      if (this.occ=="new")
        return "Einzeleingabe"
      else if (this.occ=="subsample")
        return this.sample.location_name
      else
        return this.sample.location_name
    }
  },
  ionViewWillEnter() {
    this.sample = this.storage.samples[this.route.params.sample]
    this.occ = this.route.params.occ
    this.filteredOptions = []
    console.log("search: "+this.occ)
  },
  ionViewDidEnter() {
    this.$refs.searchbar.$el.value = ""
    this.$refs.searchbar.$el.setFocus()
  },
  created () {
    this.route = useRoute()
    this.sample = this.storage.samples[this.route.params.sample]
    this.occ = this.route.params.occ
    db = Object.assign({}, window.db)
  },
  methods: {
    async onInputChange(event) {
      if (event.target.value.length < 2) {
        return
      }
      let parts = event.target.value.split(" "), gattungen = [], arten = [], intersect = []
      if (this.storage.globalSettings.taxonNames["Wissenschaftliche Namen durchsuchen"]) {
        await db.taxa.where("genus").startsWithIgnoreCase(parts[0])
          .each(art => {
            gattungen.push(art.id)
          })
        if(parts[1]) {
          await db.taxa.where("species").startsWithIgnoreCase(parts[1])
            .each(art => {arten.push(art.id)})
          intersect = gattungen.filter(n => arten.includes(n))
        }
        else
          intersect = gattungen
      }
      if (this.storage.globalSettings.taxonNames["Deutsche Namen durchsuchen"]) {
        await db.taxa.where("commonName1").startsWithIgnoreCase(parts[0])
          .each(art => {
            intersect.push(art.id)
          })
        await db.taxa.where("commonName2").startsWithIgnoreCase(parts[0])
          .each(art => {
            intersect.push(art.id)
          })
      }
      let species = await db.taxa.where('id').anyOf(intersect).toArray()
      let data = [], taxonLists = {}
      taxonLists[process.env.VUE_APP_TAXON_LIST] = {isChecked: true}
      for(const art of species) {
        for (const [key, value] of Object.entries(taxonLists)) {
          if (art.taxonListId==key&&value.isChecked) {
            if (this.storage.globalSettings.taxonNames["Nur akzeptierte Namen anzeigen"]&&art.preferred=="t")
              data.push({id: art.id, Art: art.name+
              (art.commonName&&this.storage.globalSettings.taxonNames["Deutsche Namen anzeigen"]?" - "+art.commonName:"")})
            else if (!this.storage.globalSettings.taxonNames["Nur akzeptierte Namen anzeigen"])
              data.push({id: art.id, Art: art.name+
              (art.commonName&&this.storage.globalSettings.taxonNames["Deutsche Namen anzeigen"]?" - "+art.commonName:"")})
          }
        }
      }
      this.filteredOptions = data.sort((a, b) => {
          if(a.Art > b.Art) { return 1 }
          if(a.Art < b.Art) { return -1 }
          return 0
        }).slice(0,30)
    },
    async onSelected(selected) {
      if (this.occ=="new") {
        this.sample = this.createSample("singleocc")
        this.occ = this.createOcc(this.sample).id
        this.sample.occurrences[this.occ].speciesId = selected.id
        this.sample.occurrences[this.occ].speciesName = selected.Art
        this.$router.push({name: 'singleocc', params: {sample: this.sample.id, occ: this.occ}})
      }
      else if (this.occ=="subsample") {
        let found = false
        for (const subSample of this.sample.samples) {
          if (found) {
            break
          }
          for (const occ of Object.values(this.storage.samples[subSample].occurrences)) {
            console.log(occ.speciesId, selected.id)
            if (occ.speciesId==selected.id)
              found = true
          }
        }
        let confirmation = {}
        confirmation.role = "ok"
        if (found&&this.storage.globalSettings.taxonNames["Hinweis schon gefundenes Taxon"])
          confirmation = await CONFIG.headerAlert("Art wurde schon in diese Liste aufgenommen, trotz angehakter Hinweis-Option erfassen?", "", [{text:'Abbruch'},{text:'OK',role:'ok'}])
        if (confirmation.role==="ok") {
          this.sample = this.createSample("subplot", this.sample)
          this.occ = this.createOcc(this.sample).id
          this.sample.occurrences[this.occ].speciesId = selected.id
          this.sample.occurrences[this.occ].speciesName = selected.Art
          this.$router.push({name: 'singleocc', params: {sample: this.sample.id, occ: this.occ}})
        }
      }
      else {
          this.sample.occurrences[this.occ].speciesId = selected.id
          this.sample.occurrences[this.occ].speciesName = selected.Art
          this.$router.push({name: 'singleocc', params: {sample: this.sample.id, occ: this.occ}})
      }
    },
  }
}
</script>

<style>
h3 {
  font-size: 1rem!important;
}
.alert-wrapper .alert-message {
  font-size: 1rem;
}
.alert-wrapper .alert-title {
  font-size: 1.1rem;
}
.alert-wrapper .alert-button {
  font-size: 1.1rem;
}
</style>