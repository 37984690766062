<template>
  <ion-app>
    <div id="app" v-if="standalone || isCordova || nodeEnv != 'production'">
      <ion-router-outlet />
    </div>
    <div class="appPaddings" v-else>
      <div class="rowi">
        <div class="columni">
          <img src="img/ScreenListe.webp">
        </div>
        <div class="columni">
          <img src="img/ScreenFunde.webp">
        </div>
        <div class="columni">
          <img src="img/ScreenKarte.webp">
        </div>
      </div>
      <p>Diese weiter unten detailliert beschriebene progressive Webapp Flora-SH kann hier kostenlos nach folgendem Standard installiert werden: Im Browser <strong>Safari</strong> auf iPhones und iPads unten durch Antippen des Teilensymbols und Auswählen von "Zum Home-Bildschirm". Auf <strong>Chrome/Firefox/Edge</strong> durch Auswahl des Installationssymbols in der Adressleiste oder mit dem Menü. Falls bereits installiert, wird anstatt dessen das Symbol zum Öffnen angezeigt, aber besser benutzt man dann direkt das Appsymbol der jeweiligen Plattform - diese Informationsseite wird nach der Installation nicht mehr benötigt. Beim Installieren werden die App-Inhalte im Hintergrund heruntergeladen, danach benötigt die App nur für eventuelle Updates, Hoch- und Herunterladen von Daten mit Attributen das Internet.</p>
      <h1 style="font-size:1.2em">Beschreibung Version 1.2.2</h1>
      <p class="">Die FFIpS-App wird vom Rote-Liste-Zentrum zur Verfügung gestellt.<br><br>Wesentliches Ziel der Flora-SH-App ist grundlegendes Vereinfachen der Meldung von Artbeobachtungen für das zugehörige Informationsportal. Gemeldete Beobachtungen können auf dem Portal von Artspezialisten verifiziert oder plausibilisiert werden und stehen anderen Nutzern zur Recherche zur Verfügung. Nutzer müssen vor Nutzung der App auf dem Portal (www.flora-sh.deutschlandflora.de) angemeldet sein.</p>
    </div>
  </ion-app>
</template>
<script>
import { IonApp, IonRouterOutlet, useBackButton } from '@ionic/vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet
  },
  data () {
    return {
      standalone: window.matchMedia('(display-mode: standalone)').matches,
      isCordova: window.cordova,
      nodeEnv: process.env.NODE_ENV
    }
  },
  created () {
    document.addEventListener('ionBackButton', (ev) => {
      ev.detail.register(10, () => {
        console.log('Handler was called!')
      })
    })
    window.matchMedia('(display-mode: standalone)').addEventListener('change', (evt) => {
      window.location.reload()
    })
  },
})
</script>
<style>
.appPaddings {
  background-color: inherit;
  overflow: auto;
  padding-left: .3rem;
  padding-right: .3rem;
  position: absolute;
  width: 100%;
  height: 100vh;
}
.rowi {
  display: flex;
}

.columni {
  flex: 33.33%;
  padding-left: 1%;
  padding-right: 1%;
}
.material-icons.materialIcons {
  font-size: 2rem;
  position: relative;
  top: .6rem;
  line-height: 10%;
}
.material-icons.icons-menu {
  font-size: 1rem;
  position: relative;
  top: .4rem;
}
.material-icons.crosshair {
  font-size: 40px;
  color: magenta;
  opacity: .7;
  left: -14px;
  top: -14px;
  z-index: 10000;
  cursor: grab;
}
.material-icons.locate::before { 
  content: "gps_fixed";
}
.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}
.marker-pin::after {
  content: '';
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
/*background: #fff;*/
  position: absolute;
  border-radius: 50%;
}

.custom-div-icon i {
  position: absolute;
  width: 1.7rem;
  font-size: .9rem;
  line-height: .8rem;
  color: white;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}
</style>
