<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="secondary" style="--padding-end:0">
        <ion-buttons slot="start">
          <ion-back-button text="" defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Karte</ion-title>
      </ion-toolbar>
      <ion-grid>
        <ion-row class="ion-align-items-start">
          <ion-col size="7" class="ion-align-self-start">
            <ion-item>
              <ion-label position="stacked">Koordinaten</ion-label>
              <ion-input type="text" v-model="coords" @input="inputCoords"/>
            </ion-item>
          </ion-col>
          <ion-col size="5">
            <ion-item>
              <ion-label position="stacked">{{storage.globalSettings.gridType==9?"Minutenfeld (QXY)":"Quadrant (QQQ)"}}</ion-label>
              <ion-input readonly :value=tkTxt />
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-header>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button v-if="sample.geoType" color="secondary" size="small" @click="posOK">
      <ion-icon :src="ios?'assets/del-add-point-outline.svg':'data:image/svg;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTcuNSA2QzE1LjU2NzcgNiAxNCA3LjYyMzM3IDE0IDkuNjIyNzhDMTQgMTEuOTIzNCAxNi4zMzMzIDE1LjU2OTIgMTcuMTkwMSAxNi44Mjg0QzE3LjIyNTcgMTYuODgxNiAxNy4yNzIzIDE2LjkyNDggMTcuMzI2MSAxNi45NTQ2QzE3LjM4IDE2Ljk4NDUgMTcuNDM5NiAxNyAxNy41IDE3QzE3LjU2MDQgMTcgMTcuNjIgMTYuOTg0NSAxNy42NzM5IDE2Ljk1NDZDMTcuNzI3NyAxNi45MjQ4IDE3Ljc3NDMgMTYuODgxNiAxNy44MDk5IDE2LjgyODRDMTguNjY2NyAxNS41Njk3IDIxIDExLjkyNTIgMjEgOS42MjI3OEMyMSA3LjYyMzM3IDE5LjQzMjMgNiAxNy41IDZaIiBzdHJva2U9IiNGNEY1RjgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMTcuNSAxMUMxOC4zMjg0IDExIDE5IDEwLjMyODQgMTkgOS41QzE5IDguNjcxNTcgMTguMzI4NCA4IDE3LjUgOEMxNi42NzE2IDggMTYgOC42NzE1NyAxNiA5LjVDMTYgMTAuMzI4NCAxNi42NzE2IDExIDE3LjUgMTFaIiBzdHJva2U9IiNGNEY1RjgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMTAuNzUgNlYxOS41TTE3LjUgMTIuNzVINCIgc3Ryb2tlPSIjRjRGNUY4IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+'"></ion-icon>
    </ion-fab-button>
    <ion-fab-button v-if="sample.geoType" color="secondary" size="small" @click="undoPoint">
      <ion-icon :src="ios?'assets/del-remove-point-outline.svg':'data:image/svg;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTcuNSA2QzE1LjU2NzcgNiAxNCA3LjYyMzM3IDE0IDkuNjIyNzhDMTQgMTEuOTIzNCAxNi4zMzMzIDE1LjU2OTIgMTcuMTkwMSAxNi44Mjg0QzE3LjIyNTcgMTYuODgxNiAxNy4yNzIzIDE2LjkyNDggMTcuMzI2MSAxNi45NTQ2QzE3LjM4IDE2Ljk4NDUgMTcuNDM5NiAxNyAxNy41IDE3QzE3LjU2MDQgMTcgMTcuNjIgMTYuOTg0NSAxNy42NzM5IDE2Ljk1NDZDMTcuNzI3NyAxNi45MjQ4IDE3Ljc3NDMgMTYuODgxNiAxNy44MDk5IDE2LjgyODRDMTguNjY2NyAxNS41Njk3IDIxIDExLjkyNTIgMjEgOS42MjI3OEMyMSA3LjYyMzM3IDE5LjQzMjMgNiAxNy41IDZaIiBzdHJva2U9IiNGNEY1RjgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMTcuNSAxMUMxOC4zMjg0IDExIDE5IDEwLjMyODQgMTkgOS41QzE5IDguNjcxNTcgMTguMzI4NCA4IDE3LjUgOEMxNi42NzE2IDggMTYgOC42NzE1NyAxNiA5LjVDMTYgMTAuMzI4NCAxNi42NzE2IDExIDE3LjUgMTFaIiBzdHJva2U9IiNGNEY1RjgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMTcuNSAxMi43NUg0IiBzdHJva2U9IiNGNEY1RjgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4='"></ion-icon>
    </ion-fab-button>
    <ion-fab-button :activated="false" @click="onReady">
      <ion-icon :icon="checkmarkDoneOutline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab vertical="bottom" horizontal="start" slot="fixed">
    <ion-fab-button :activated="false">
      <ion-icon v-if="sample.geoType=='polygon'" :icon="bookmarkOutline"></ion-icon>
      <ion-icon v-else-if="sample.geoType=='polyline'" :icon="analyticsOutline"></ion-icon>
      <ion-icon v-else :icon="gitCommitOutline"></ion-icon>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-fab-button color="primary" @click="setPoint()">
        <ion-icon :icon="gitCommitOutline"></ion-icon>
      </ion-fab-button>
      <ion-fab-button color="primary" @click="setPolyline">
        <ion-icon :icon="analyticsOutline"></ion-icon>
      </ion-fab-button>
      <ion-fab-button color="primary" @click="setPolygon">
        <ion-icon :icon="bookmarkOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  <ion-content>
  <div style="height:100%">
    <LeafletMap
      :center="center?center:storage.config.mapView.center" :pointClick="false"
      :zoom="storage.config.mapView.zoom" :offline="false"
      :controls="true" :mapid="'multi'" @componentready="setComponent">
    </LeafletMap>
  </div>
  </ion-content>
  </ion-page>
</template>

<script>
import { CONFIG } from '../helpers/config.js'
import LeafletMap from '../components/LeafletMap.vue'
import L from 'leaflet';
import storeMixin from '../mixins/store.js'
import utilsMixin from '../mixins/utils.js'
import { useRoute } from 'vue-router'
import {
  IonPage,
  IonButtons,
  IonBackButton,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonInput,
  IonLabel,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  isPlatform
} from '@ionic/vue'
import { 
  analyticsOutline,
  gitCommitOutline,
  checkmarkDoneOutline,
  bookmarkOutline
} from 'ionicons/icons'
let coordInput = false
export default {
  inject: ['storage'],
  mixins: [storeMixin, utilsMixin],
  components: {
    LeafletMap,
    IonPage,
    IonButtons,
    IonBackButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonFab,
    IonFabButton,
    IonFabList,
    IonIcon,
    IonInput,
    IonLabel,
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
  },
  data () {
    return {
      route: useRoute(),
      id: null,
      sample: null,
      coords: '',
      tkTxt: "",
      mapZoom: 0,
      mapComponent: null,
      previewGroup: null,
      polylineGroup: null,
      gridCell: [],
      leafletPolygon: null,
      crosshair: null,
      analyticsOutline,
      gitCommitOutline,
      checkmarkDoneOutline,
      bookmarkOutline,
      ios: isPlatform("ios")
    }
  },
  props: {
    center: {},
    zoom: {
      type: Number
    },
  },
  created() {
    this.id = this.route.params.id
    this.sample = this.storage.samples[this.id]
},
  ionViewWillEnter() {
    this.id = this.route.params.id
    this.sample = this.storage.samples[this.id]
    this.setComponent(this.mapComponent)
  },
  ionViewDidEnter() {
    this.mapComponent.map.invalidateSize()
    if (!this.sample.geoType)
      this.sample.polyline = []

  },
  methods: {
    setComponent(component) {
      if (!component.map)
        component.initMap(true)
      this.mapComponent = component
      let map = component.map
      let latLng = map.getCenter()
      this.coords = latLng.lng.toFixed(5)+", "+latLng.lat.toFixed(6)
      let myIcon = L.divIcon({className: 'material-icons crosshair', html: 'center_focus_weak'})
      if (this.crosshair != null) {
        this.mapComponent.map.removeLayer(this.crosshair)
      }
      this.crosshair = L.marker(latLng, {icon: myIcon})
      this.crosshair.addTo(map)
      if (this.polylineGroup != null) {
        this.mapComponent.map.removeLayer(this.polylineGroup)
      }
      this.polylineGroup = L.featureGroup().addTo(map)
      if (this.previewGroup != null) {
        map.removeLayer(this.previewGroup)
      }
      if (component.occsGroup) {
        component.occsGroup.clearLayers()
        component.occsGroup.remove()
      }
      component.occsGroup = L.featureGroup().addTo(map)
      this.occsMarkers(this.sample.samples, component, null)
      if(this.sample.polyline.length==1) {
        map.setView([this.sample.yCentroid, this.sample.xCentroid], 16)
        let circle = L.circle([this.sample.yCentroid, this.sample.xCentroid], {
          radius: this.sample.blur?this.sample.blur/2:10,
          color: 'orange',
          fillOpacity: 0,
          fillColor : 'white'})
          .addTo(this.polylineGroup)
        circle.ID = this.sample.id
      }
      if(this.sample.polyline.length>1) {
        let  options = {color: 'blue', lineCap: 'butt', weight: 3, opacity: .3}
        let leafletPolyline = L.polyline(this.sample.polyline, options)
        leafletPolyline.addTo(this.polylineGroup)
        let lastPoint = this.sample.polyline[this.sample.polyline.length-1]
        map.setView([lastPoint[0], lastPoint[1]], 16)
      }
      map.on('move', () => {
        latLng = map.getCenter()
        this.crosshair.setLatLng(latLng)
        if(!coordInput) {
          this.coords = latLng.lng.toString().substr(0,8)
            +", "+latLng.lat.toString().substr(0,9)
        }
        if (this.storage.globalSettings.gridType==9)
          this.tkTxt = this.projMinutes(latLng.lng, latLng.lat)
        else
          this.tkTxt = this.projTK(latLng.lng, latLng.lat)
        if(this.sample.polyline.length>0) {
          if (this.previewGroup != null) {
            map.removeLayer(this.previewGroup)
          }
          this.previewGroup = L.featureGroup().addTo(map)
          let  options = {color: 'red', lineCap: 'butt', weight: 3, opacity: .3, dashArray: '4'}
          let endPolyline = this.sample.polyline[this.sample.polyline.length-1]
          if (this.sample.geoType=="polygon")
            L.polyline([endPolyline, [latLng.lat, latLng.lng], this.sample.polyline[0]], options)
              .addTo(this.previewGroup)
          else
            L.polyline([endPolyline, [latLng.lat, latLng.lng]], options)
              .addTo(this.previewGroup)
        }
      })
      map.on('moveend', () => {
        coordInput = false
      })
      map.on('locationerror', (e) => {
        component.lc.stop()
        console.log(e)
      })
    },
    inputCoords(e) {
      let pos = e.target.value.indexOf(', ')
      let latLng = [e.target.value.substring(pos+2),
        e.target.value.substring(0, pos)]
      coordInput = true
      this.mapComponent.map.setView(latLng, this.mapComponent.map.getZoom())
    },
    drawPreview() {
      if (this.previewGroup != null) {
        this.mapComponent.map.removeLayer(this.previewGroup)
       }
      if (this.polylineGroup != null) {
        this.mapComponent.map.removeLayer(this.polylineGroup)
      }
      if(this.sample.polyline.length>0) {
        this.polylineGroup = L.featureGroup().addTo(this.mapComponent.map)
        let  options = {color: 'blue', lineCap: 'butt', weight: 3, opacity: .3}
        L.polyline(this.sample.polyline, options)
          .addTo(this.polylineGroup)
        this.previewGroup = L.featureGroup().addTo(this.mapComponent.map)
        options = {color: 'red', lineCap: 'butt', weight: 3, opacity: .3, dashArray: '4'}
        let endPolyline = this.sample.polyline[this.sample.polyline.length-1]
        let latLng = this.mapComponent.map.getCenter()
        if (this.sample.geoType=="polygon")
          L.polyline([endPolyline, [latLng.lat, latLng.lng], this.sample.polyline[0]], options)
            .addTo(this.previewGroup)
        else
          L.polyline([endPolyline, [latLng.lat, latLng.lng]], options)
            .addTo(this.previewGroup)
      }
    },
    posOK () {
      let pos = this.coords.indexOf(', ')
      this.sample.polyline.push(
        [Number(this.coords.substring(pos+2)), Number(this.coords.substring(0, pos))])
      this.drawPreview()
    },
    async onReady() {
      if (this.sample.geoType=="polygon"&&this.sample.polyline.length<2)
        await CONFIG.headerAlert("Für eine Fläche sind mindestens 3 Stützpunkte erforderlich", "", [{text:'OK',role:'ok'}])
      else if (this.sample.geoType=="polyline"&&this.sample.polyline.length<1)
        await CONFIG.headerAlert("Für eine Strecke sind mindestens 2 Stützpunkte erforderlich", "", [{text:'OK',role:'ok'}])
      else {        
        let pos = this.coords.indexOf(', ')
        this.sample.polyline.push(
          [Number(this.coords.substring(pos+2)), Number(this.coords.substring(0, pos))])
        if(this.sample.polyline.length>2) {
          let leafletPolyline = L.polyline(this.sample.polyline)
          let latLng = leafletPolyline.getBounds().getCenter()
          this.sample.xCentroid = latLng.lng.toFixed(5)
          this.sample.yCentroid = latLng.lat.toFixed(6)
        }
        else {
          this.sample.xCentroid = Number(this.coords.substring(0, pos))
          this.sample.yCentroid = Number(this.coords.substring(pos+2))
        }
        this.sample.blur = this.sample[12] = this.sample[26] = this.sample[60] = null
        this.sample[61] = "map"
        this.setComponent(this.mapComponent)
        this.$router.push({name: 'singleocc', params: {sample: this.id, occ: "multi"}})
      }
    },
    undoPoint() {
      this.sample.polyline.pop()
      this.drawPreview()
    },
    setPoint() {
      this.sample.geoType = null
      this.sample.polyline = []
      this.drawPreview()
    },
    setPolyline() {
      this.sample.geoType = "polyline"
      this.drawPreview()
    },
    setPolygon() {
      this.sample.geoType = "polygon"
      this.drawPreview()
    }
  }
}
</script>
<style scoped>
ion-item {
  --padding-start: 0;
  --inner-padding-top: 0;
}
ion-grid {
  --ion-grid-padding: 0;
}
ion-fab-button {
  margin-top: .7rem;
}
</style>