import proj4 from 'proj4'
proj4.defs("EPSG:4314","+proj=longlat +ellps=bessel +towgs84=598.1,73.7,418.2,0.202,0.045,-2.455,6.7 +no_defs")
import { isPlatform, loadingController} from '@ionic/vue'
import device from '../helpers/device.js'
import axios from 'axios'
import { CONFIG } from '../helpers/config.js'
import { format, parseISO } from 'date-fns'
export default {
  data () {
    return {
      delimiter: ",",
      attrNames: [],
      headerTxt: "",
      dataTxt: "",
      textValues: {},
    }
  },
  methods: {
    initAttrs() {
      let occAttrs = "", smpAttrs = ""
      for(const group of Object.values(this.storage.attributes)) {
        if (group.groupName!="") {
          for(const attribute of Object.values(group.attributes)) {
            if (attribute.attributeType=='S') {
              smpAttrs += attribute.attrName+','
            }
            else {
              occAttrs += attribute.attrName+','
            }
          }
        }
      }
      this.storage.smpAttrs = smpAttrs.substring(0, smpAttrs.length-1)
      this.storage.occAttrs = occAttrs.substring(0, occAttrs.length-1)
    },
    singleOcc(sampleId) {
      let key = Object.keys(this.storage.samples[sampleId].occurrences)[0]
      return this.storage.samples[sampleId].occurrences[key]
    },
    initSamples(status, samples) {
      let split
      proj4.defs("EPSG:25832", '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs')
      for (const s of samples) {
        let sample = {}
//        console.log(s.entered_sref, s.entered_sref_output, s.entered_sref_system)
        if (s.entered_sref_output&&s.entered_sref_system=="4326") {
          if (s.entered_sref_output.indexOf(", ")!=-1) {
            split = s.entered_sref_output.split(", ")
          }
          else  {
            split = s.entered_sref_output.split(" ")
          }
          if (split.length==2) {
            sample.yCentroid = split[0].substring(0, split[0].length-1)
            sample.xCentroid = split[1].substring(0, split[1].length-1)
          }
        }
        else if (s.entered_sref&&s.entered_sref_system=="4326") {
          if (s.entered_sref.indexOf(", ")!=-1) {
            let split = s.entered_sref.split(", ")
            sample.yCentroid = split[0].substring(0, split[0].length-1)
            sample.xCentroid = split[1].substring(0, split[1].length-1)
          }
          else  {
            sample.yCentroid = s.entered_sref.split(" ")[0]
            sample.xCentroid = s.entered_sref.split(" ")[1]
          }
        }
        else if (s.entered_sref_output&&s.entered_sref_system=="25832") {
          if (s.entered_sref_output.indexOf(", ")!=-1) {
            split = s.entered_sref_output.split(", ")
          }
          else  {
            split = s.entered_sref_output.split(" ")
          }
          if (split.length==2) {
            let proj = proj4('EPSG:25832','EPSG:4326', [Number(split[0]), Number(split[1])])
            sample.xCentroid = proj[0]
            sample.yCentroid = proj[1]
          }
        }
        /*
        else if(s.geom) {
          split = (s.geom.substring(6, s.geom.length-1)).split(" ")
          let proj = proj4('EPSG:3857','EPSG:4326', [Number(split[0]), Number(split[1])])
          sample.xCentroid = proj[0]
          sample.yCentroid = proj[1]
        }
        */
        sample.polyline = []
        sample.status = status
        sample.type = "singleocc"
        sample.id = s.sample_id
        sample.comment = s.comment
        sample.recorder_names = s.recorder_names
        sample.location_name = s.location_name
        sample.tk = s.grids_tk
        sample.occurrences = {}
        sample.occurrences[s.occurrence_id] = {}
        sample.occurrences[s.occurrence_id].id = s.occurrence_id
        sample.occurrences[s.occurrence_id].speciesId = s.taxa_taxon_list_id
        sample.occurrences[s.occurrence_id].speciesName = s.taxon
        sample.occurrences[s.occurrence_id].comment = s.comment
        for(const group of Object.values(this.storage.attributes)) {
          for(const attribute of Object.values(group.attributes)) {
            if (attribute.controlType=='checkbox_group'||attribute.controlType=='listbox') {
              sample[attribute.attrName] = []
            }
            if (attribute.attributeType=='S') {
              if(s["attr_sample_"+attribute.attrName]) {
                if (attribute.controlType=='checkbox_group'||attribute.controlType=='listbox') {
                  sample[attribute.attrName] = s["attr_sample_"+attribute.attrName].split(", ")
                }
                else
                sample[attribute.attrName] = s["attr_sample_"+attribute.attrName]
              }
              else if (attribute.attrName=="date")
                sample.date = (s["cache_created_on_raw"].replace(" ", "T"))+"Z"
            }
            else {
              if (attribute.controlType=='checkbox_group'||attribute.controlType=='listbox') {
                sample.occurrences[s.occurrence_id][attribute.attrName] = []
              }
              if(s["attr_occurrence_"+attribute.attrName]) {
                if (attribute.controlType=='checkbox_group'||attribute.controlType=='listbox') {
                  sample.occurrences[s.occurrence_id][attribute.attrName] = s["attr_occurrence_"+attribute.attrName].split(", ")
                }
                else
                  sample.occurrences[s.occurrence_id][attribute.attrName] = s["attr_occurrence_"+attribute.attrName]
              }
            }
          }
        }
        sample.fotos = {}
        sample.occurrences[s.occurrence_id].fotos = {}
        this.storage.samples[sample.id] = sample
      }
    },
    checkForm(objType, obj) {
      if (obj) {
      let regInt = /^[1-9]\d*$/
      if (objType=="O") {
        if(!obj.speciesName)
          return "Bitte Taxon auswählen"
      }
      else {
        if(!obj.xCentroid)
          return "Bitte auf die Karte tippen und durch Verschieben des Kartenmittelpunkts Punkt, Linie oder Fläche markieren"
      }
      for(const group of Object.values(this.storage.attributes)) {
        for(const attribute of Object.values(group.attributes)) {
          if (attribute.attributeType==objType) {
            if (obj[attribute.attrName]==="")
              obj[attribute.attrName] = null
            if (attribute.validationRules.indexOf('required')!=-1&&!obj[attribute.attrName])
              return "Bitte "+attribute.text+" eingeben"
            if (obj[attribute.attrName]) {
              if (attribute.type=="int"&&!regInt.test(obj[attribute.attrName]))
                return "Bitte "+attribute.text+" als positive Ganzzahl eingeben"
              if(Number(obj[attribute.attrName])<attribute.min||Number(obj[attribute.attrName])>attribute.max)
                return attribute.text+" muss zwischen "+attribute.min+" und "+attribute.max+" liegen"
              if (attribute.validationRules.indexOf('length[0')!=-1)
                if (obj[attribute.attrName].length>attribute.validationRules.split('length[0')[1].slice(1,-1))
                 return attribute.text+" darf nicht mehr als "+attribute.validationRules.split('length[0')[1].slice(1,-1)+ " Zeichen enthalten"
            }
          }
        }
      }
      }
      return ""
    },
    filterAttrType(type) {
      for(const group of Object.values(this.storage.attributes)) {
        for(const attr of Object.values(group.attributes)) {
          if (attr.attributeType==type) {
            this.headerTxt += this.delimiter+attr.text
            this.attrNames.push(attr)
          }
        }
      }
    },
    testNull(attr) {
      return attr?"\""+attr+"\"":""
    },
    indexTextValues() {
      for(const group of Object.values(this.storage.attributes)) {
        for(const attr of Object.values(group.attributes)) {
          if (attr.type=='L') {
            this.textValues[attr.attributeType+attr.attrName] = {}
            for(const item of Object.values(attr.values)) {
              this.textValues[attr.attributeType+attr.attrName][item.value] = item.text
            }
          }
        }
      }  
    },
    listTexts(obj, type) {
      for (const value of this.attrNames) {
        if (value.attributeType==type) {
          if (this.storage.globalSettings.shareType == "gpx") {
            if (obj[value.attrName]) {
              value.text = value.text.replace(/[\)\( /]/g, "")
              if (value.type=="L")
                this.dataTxt += "\n      <"+value.text+">"+this.textValues[type+value.attrName][obj[value.attrName]]+"</"+value.text+">"
              else
                this.dataTxt += "\n      <"+value.text+">"+obj[value.attrName]+"</"+value.text+">"
            }
          }
          else {
            if (value.type=="L")
              this.dataTxt += this.delimiter+this.testNull(this.textValues[type+value.attrName][obj[value.attrName]])
            else if (value.type=="D")
              this.dataTxt += this.delimiter+format(parseISO(obj[value.attrName]), 'dd.MM.yyyy kk:mm')
            else
              this.dataTxt += this.delimiter+this.testNull(obj[value.attrName])
          }
        }
      }
    },
    csvHeader() {
      this.headerTxt = "Aufnahmenr."+this.delimiter+"Raumreferenz"+this.delimiter+"Raumreferenzsystem"+this.delimiter+"GPS-Genauigkeit"
      this.attrNames = []
      this.filterAttrType("S")
      this.headerTxt += this.delimiter+"Aufnahme-Kommentar"+this.delimiter+"Raumreferenz-Subsample"+this.delimiter+"GPS-Genauigkeit-Subsample"+this.delimiter+"Taxon"+this.delimiter+"Deutscher Name"
      this.filterAttrType("O")
      this.headerTxt += this.delimiter+"Taxon-Kommentar\n"
    },
    coordsRecorder(coord) {
      return Number.parseFloat(coord).toFixed(5).replace(".", ",")
    },
    async csvData(sample) {
      if (sample.type=="singleocc") {
        for (const occ of Object.values(sample.occurrences)) {
          this.sampleData(sample)
          await this.occData(occ, null, sample)
        }
      }
      else {
        for (const sampleId of sample.samples) {
          this.sampleData(sample)
          let key = Object.keys(this.storage.samples[sampleId].occurrences)[0]
          let occ = this.storage.samples[sampleId].occurrences[key]
          await this.occData(occ, sampleId, sample)
        }
      }
      if (this.storage.globalSettings.shareType == "gpx") {
        this.dataTxt += `
  <trk>
    <name>${sample.location_name}</name>
    <desc>${sample.id}</desc>
    <trkseg>`
        if (sample.polyline.length>0) {
          for (const point of sample.polyline) {
            this.dataTxt += `
      <trkpt lat="${point[0]}" lon="${point[1]}">
      </trkpt>`
          }
          if (sample.geoType=="polygon") {
            this.dataTxt += `
      <trkpt lat="${sample.polyline[0][0]}" lon="${sample.polyline[0][1]}">
      </trkpt>`
          }
        }
        else {
          this.dataTxt += `
      <trkpt lat="${sample.yCentroid}" lon="${sample.xCentroid}">
      </trkpt>`
        }
        this.dataTxt += `
    </trkseg>
  </trk>`
      }
    },
    sampleData(sample) {
      if (this.storage.globalSettings.shareType == "gpx") {
      }
      else {
        this.dataTxt += sample.id+
        this.delimiter+"\""+this.coordsRecorder(sample.yCentroid)+"N; "+
        this.coordsRecorder(sample.xCentroid)+"E\""+
        this.delimiter+"LTLN"+
        this.delimiter+this.testNull(sample.blur)
        this.listTexts(sample, "S")
        this.dataTxt += this.delimiter+this.testNull(sample.comment)
      }
    },
    async occData(occ, sampleId, sample) {
      let species = await db.taxa.where({id: occ.speciesId}).first()
      if (this.storage.globalSettings.shareType == "gpx") {
        if (sampleId) {
          this.dataTxt += `
  <wpt lat="${this.storage.samples[sampleId].yCentroid}" lon="${this.storage.samples[sampleId].xCentroid}">`
        }
        else {
          this.dataTxt += `
  <wpt lat="${sample.yCentroid}" lon="${sample.xCentroid}">`
        }
        this.dataTxt += `
    <name>${occ.speciesName}</name>
    <extensions>
      <id>${sample.id}</id>
      <desc>${sample.location_name}</desc>`
        this.attrNames = []
        this.filterAttrType("S")
        this.listTexts(sample, "S")
        this.attrNames = []
        this.filterAttrType("O")
        this.headerTxt = ""
        this.listTexts(occ, "O")
        this.dataTxt += `
    </extensions>
  </wpt>`
      }
      else {
        if (sampleId) {
          this.dataTxt += this.delimiter+"\""+this.coordsRecorder(this.storage.samples[sampleId].yCentroid)+"N; "+
            this.coordsRecorder(this.storage.samples[sampleId].xCentroid)+"E\""+
            this.delimiter+this.testNull(this.storage.samples[sampleId].blur)
        }
        else
          this.dataTxt += this.delimiter+this.delimiter
        this.dataTxt += this.delimiter+occ.speciesName+this.delimiter+this.testNull(species.commonName)
        this.listTexts(occ, "O")
        this.dataTxt += this.delimiter+this.testNull(occ.comment)+"\n"
      }
  },
    forceDownload(blob, filename) {
      const a = document.createElement('a')
      a.download = filename
      a.href = blob
      // For Firefox https://stackoverflow.com/a/32226068
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    shareFile(fileName, dataObj) {
      let onSuccess = function(result) {
        console.log("Share completed? " + result.completed) // On Android apps mostly return false even while it's true
        console.log("Shared to app: " + result.app) // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
      }
      let onError = function(msg) {
        console.log("Sharing failed with message: " + msg)
      }
      let localDirectory
      if (window.cordova) {
        if (isPlatform("ios")) {
          localDirectory = window.cordova.file.documentsDirectory
        } else if (device.isAndroid()) {
          localDirectory = window.cordova.file.externalDataDirectory
        }
        window.resolveLocalFileSystemURL(localDirectory, (dirEntry) => {
          dirEntry.getFile(fileName, {create: true, exclusive: false}, function(fileEntry) {
            fileEntry.createWriter(function (fileWriter) {
              fileWriter.onwriteend = function() {
                console.log("Successful file write..."+fileEntry.toURL())
                const options = {
                  message: "Exportdatei im CSV-Format (Komma getrenntes Textformat), die in Tabellenkalkulationen, Datenbanken, Erfassussungssoftware u.a. importiert und dort bearbeitet werden kann.",
                  subject: "Export CSV Artenkenner-App",
                  files: [fileEntry.toURL()],
                }
                window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError)   
              }
              fileWriter.onerror = function (e) {
                console.log("Failed file write: " + e.toString())
              } 
              fileWriter.write(dataObj)
            })
          })
        })
      }
      else {
        let blobUrl = window.URL.createObjectURL(dataObj)
        this.forceDownload(blobUrl, fileName)
      }
    },
    async presentLoading(message) {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: message,
          backdropDismiss: false,
          spinner: "crescent",
          translucent: true,
        })
        await loading.present()
        return loading
    },
    async indiciaAttributes (reportName, attr) {
      let groupIndex, prevGroup="-1"
      const loading = this.presentLoading("Attribute werden geladen")
      await axios.get(CONFIG.api+'/reports'+reportName+'?survey_id='+localStorage.getItem('surveyIndicia')+'&website_ids='+process.env.VUE_APP_WEBSITE).then(async response => {
        for (const field of response.data.data) {
          if (field.inner_block_name!=prevGroup) {
            groupIndex = attr.push({groupName: field.inner_block_name, attributes: []})-1
            prevGroup = field.inner_block_name
          }
          let index = attr[groupIndex].attributes.push({
            attrName: field.id,
            text: field.caption,
            type: field.data_type,
            controlType: field.control_type,
            controlWeight: field.control_weight,
            innerBlockWeight: field.inner_block_weight,
            innerBlockName: field.inner_block_name,
            validationRules: field.validation_rules,
            defaultValue: field.default_value,
            attributeType: field.attribute_type,
            sampleMethod: field.sample_method,
            values: [],
          })
          if (field.termlist_id!=null) {
            await axios.get(CONFIG.api+'/reports/mobile/terms/apps-terms_overview.xml?termlist_id='+field.termlist_id).then(async response => {
              for (const value of response.data.data) {
                attr[groupIndex].attributes[index-1].values.push({
                  value: value.id,
                  text: value.term,
                  parent: value.parent_id
                })
              }
            })
          }
        }
      })
      loading.then(load => {load.dismiss()})
      this.initAttrs()
    },
    occsMarkers(samples, component, currentSample) {
      component.occsGroup = L.featureGroup().addTo(component.map)
      component.occsGroup.on("click", (event) => {
          this.$router.push({name: 'singleocc', params: {sample: event.layer.sampleId, occ: event.layer.occId}})
      })
      console.log("Begin for")
      let sample, marker, myIcon, words, background
      for (const sampleId of samples) {
        sample = this.storage.samples[sampleId]
        for (const occ of Object.values(sample.occurrences)) {
          if (sample.xCentroid) {
            words = occ.speciesName.split(' ')
            if (sampleId==currentSample) 
              background = "rgb(255, 166, 0)"
            else
              background = "rgb(0, 81, 255)"
            myIcon = L.divIcon({className:  "custom-div-icon",
              html: "<div style='background:"+background+";' class='marker-pin'></div><i>"+
                words[0].substr(0,3)+"<br> "+(words[1]?words[1].substr(0,2):"")+"</i>",
              iconSize: [30, 42],
              iconAnchor: [15, 42]
            })
            marker = L.marker([sample.yCentroid, sample.xCentroid], {icon: myIcon})
              .addTo(component.occsGroup)
            marker.sampleId = sampleId
            marker.occId = occ.id
          }
        }
      }
      console.log("End occMarkers")
    },
    quadrant(x, y, maxCount) {
      let q = ""
      while(q.length<maxCount) {
        if (y<=0.5) {
          if (x<=0.5) {
            q += "1"
            x *= 2
            y *= 2
          }
          else {
            q += "2"
            x = (x-0.5)*2
            y *= 2
          }
        }
        else {
          if (x<=0.5) {
            q += "3"
            x *= 2
            y = (y-0.5)*2
          }
          else {
            q += "4"
            x = (x-0.5)*2
            y = (y-0.5)*2
          }
        }
      }
      return q
    },
    projTK(x, y) {
      let xy = proj4('EPSG:4326','EPSG:4314',[x, y])
      let tk = "TK"+(Math.round((56-xy[1])*10-0.5)+100+"").slice(-2) + (Math.round((xy[0]-((5+(4/6))))*6-0.5)+100+"").slice(-2) + "/"
      return tk + this.quadrant(((xy[0]-((5+(4/6))))*6)%1, ((56-xy[1])*10)%1, 3)
    },
    projMinutes(x, y) {
    //berechnet deutsche Raster Koordinaten des Minutenfeld
    //Raster Systems
    //übergeben werden Breite und Länge im Bessel-Ellipsoid epsg:4314
    //in der Dezimalnotation: ggg.ddddd
    //calculates German grids of a "Minutenfeld" (QQQ)
    //parameters are latitude and longitude of the Bessel ellipsoid/DHDN (epsg:4314))
    //in decimal notation
    function Right(str,chr) {
      return str.substr(str.length-chr,str.length)
    }
    let xy = proj4('EPSG:4326','EPSG:4314',[x, y])
    let iLat = xy[1]
    let iLong = xy[0]
    var SIX_MINUTES = 1/10;
    var TEN_MINUTES = 1/6;
    var easting, northing;
    var yy, xx, q, y, x, y6th, x10th;
    var StrY, StrX, MTBQYX;
    var FXOrigin;
    var FYOrigin;
    FYOrigin = 55.1;
    //FXOrigin := 5.833333333333333333333;
    FXOrigin = 5 + (50/60);
    easting = ((FYOrigin - iLat)/SIX_MINUTES) + 9;
    yy = Math.floor(easting);
    northing = ((iLong - FXOrigin) * 6) + 1;
    xx = Math.floor(northing);
    y6th = Math.floor((easting - yy) * 6) + 1;
    x10th = Math.floor((northing - xx) * 10) + 1;
    // starting with 111; Starten mit 111
    q = 1;
    y = 1;
    x = 1;
    // Verschiebung berechnen für y - Work out each shift according to y6th
    if ( y6th in { 4:1, 5:1, 6:1 } ) {q = 3};
    if ( y6th in { 2:1, 5:1 } ) {y = 2};
    if ( y6th in { 3:1, 6:1} ) {y = 3};
    // Verschiebung berechnen für x - Work out each additional shift according to x10th
    if ( x10th in { 6:1, 7:1, 8:1, 9:1, 10:1 } ) {q++;};
    if ( x10th in { 2:1, 7:1 } ) {x = 2};
    if ( x10th in { 3:1, 8:1 } ) {x = 3};
    if ( x10th in { 4:1, 9:1 } ) {x = 4};
    if ( x10th in { 5:1, 10:1 } ) {x = 5};
    //Sonderbehandlung westlicher Felder
    //grids west of "00" need a special treatment
    if (xx<0) {
       StrY = Right('00' + yy, 2);
       StrX = Right('00' + (100+xx), 2);
       MTBQYX = 'W'+StrY + StrX + "/" + q + y +x;
     }
     else {
       StrY = Right(('00' + yy), 2);
       StrX = Right(('00' + xx), 2);
       MTBQYX = StrY + StrX +"/" + q + y + x;
     };

    if (yy < 0 || xx < -99 || yy > 99 || xx > 99) {
      return "Außerhalb Definition";
      }
    else {
      return MTBQYX;
      };
    }
  },
}
