<template>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button @click="status=='Meine Funde'?refreshClick():sendClick()">
      <ion-icon :icon="status=='Meine Funde'?reloadOutline:paperPlaneOutline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab v-if="status=='In Bearbeitung'" vertical="bottom" horizontal="start" slot="fixed">
    <ion-fab-button @click="shareSamples">
      <ion-icon :icon="shareSocialOutline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
    <ion-list ref="list">
    <transition-group name="vuelist">
    <span v-for="(sample, key) in samplesArray" :key="key">
      <ion-item-divider v-if="key==0?true:sample.date.substring(0, 10)!=samplesArray[key-1].date.substring(0, 10)">
        <ion-label>
          {{new Date().toJSON().substring(0, 10)==sample.date.substring(0, 10)?"Heute":dateString(sample.date).split(",")[0]}}
        </ion-label>
      </ion-item-divider>
      <span v-if="sample.type=='singleocc'">
      <ion-item :routerLink="'/singleocc/'+sample.id+'/'+singleOcc(sample.id).id" :detail="false" class="list-item">
        <ion-label>
          <div class="round-container">
              <img v-if="Object.keys(singleOcc(sample.id).fotos).length>0"
              :src="singleOcc(sample.id).fotos[Object.keys(singleOcc(sample.id).fotos)[0]]"/>
          </div>
          <span style="float:right;padding-left:.5rem;font-size:.9rem">
            {{dateString(sample.date).substr(dateString(sample.date).substr.length-10)}}
          </span>
          <ion-text style="font-size:1.1rem" v-if="sample.occurrences">
            {{singleOcc(sample.id).speciesName}}
          </ion-text><br />
          <span style="float:right">
            <ion-badge style="margin-left:.8rem">Einzel</ion-badge>
            <ion-icon @click.stop="setPopup(true, sample, $event)" :icon="ellipsisVertical" style="font-size:1.5rem;padding-left:.8rem" />
          </span>
          <ion-text style="font-size:.9rem">
            {{sample.location_name}}
          </ion-text>
        </ion-label>
      </ion-item>
      </span>
      <span v-else-if="sample.type=='plot'">
      <ion-item :routerLink="'/singleocc/'+sample.id+'/multi'" :detail="false" class="list-item">
        <ion-label>
          <div class="photo">
            <img v-if="Object.keys(sample.fotos).length>0"
              :src="sample.fotos[Object.keys(sample.fotos)[0]]"/>
          </div>
          <span style="float:right;padding-left:.5rem;font-size:.9rem">
            {{dateString(sample.date).substr(dateString(sample.date).substr.length-10)}}
          </span>
          <div style="font-size:1.1rem">{{sample.samples.length+(sample.samples.length==1?' Fund':' Funde')}}</div>
          <span style="float:right">
            <ion-badge style="margin-left:.8rem" color="secondary">Liste</ion-badge>
            <ion-icon @click.stop="setPopup(true, sample, $event)" :icon="ellipsisVertical" style="font-size:1.5rem;padding-left:.8rem" />
          </span>
          <ion-text style="font-size:.9rem">
            {{sample.location_name}}
          </ion-text>
        </ion-label>
      </ion-item>
      </span>
    </span>
    </transition-group>
    </ion-list>
  <ion-popover :is-open="popoverOpen"
    @didDismiss="setPopup(false)"
    :event="popoverEvent"
    :dismiss-on-select="true"
    :show-backdrop="true"
  >
    <ion-content>
      <ion-title>{{popoverData.location_name}}</ion-title>
      <ion-item @click="submitSample(popoverData)">Senden
        <ion-icon :icon="paperPlaneOutline" slot="start" />
      </ion-item>
      <ion-item @click="shareSample(popoverData)">Teilen
         <ion-icon :icon="shareSocialOutline" slot="start" />
      </ion-item>
      <ion-item @click="deleteItem(popoverData.id)">Löschen
        <ion-icon :icon="trashBinOutline" slot="start" />
      </ion-item>
      <ion-item>Abbruch
        <ion-icon :icon="closeOutline" slot="start" />
      </ion-item>
    </ion-content>
  </ion-popover>
</template>

<script>
import axios from 'axios'
import { CONFIG } from '../helpers/config.js'
import localForage from 'localforage'
import utilsMixin from '../mixins/utils.js'
import storeMixin from '../mixins/store.js'
import Image from '../helpers/image.js'
import * as windows1252 from 'windows-1252'
import proj4 from 'proj4'
import { 
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon, 
  IonItem, 
  IonLabel, 
  IonList,
  IonFab,
  IonFabButton,
  IonFabList,
  IonBadge,
  IonItemDivider,
  IonText,
  IonPopover
} from '@ionic/vue'
import { 
  paperPlaneOutline,
  reloadOutline,
  trashBinOutline,
  shareSocialOutline,
  closeOutline,
  ellipsisVertical
} from 'ionicons/icons'
export default {
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList,
    IonFab,
    IonFabButton,
    IonFabList,
    IonBadge,
    IonItemDivider,
    IonText,
    IonPopover
  },
  inject: ['storage'],
  mixins: [utilsMixin, storeMixin],
  name: 'samples',
  props: ['status'],
  data () {
    return {
      paperPlaneOutline,
      reloadOutline,
      trashBinOutline,
      shareSocialOutline,
      closeOutline,
      ellipsisVertical,
      samples: this.storage.samples,
      countTransmit: 0,
      subm: "t",
      popoverOpen: false,
      popoverEvent: null,
      popoverData: null
    }
  },
  created() {
    console.log("created")
    this.countTransmit = 0
    this.indexTextValues()
  },
  computed: {
    samplesArray: function () {
      const samplesA = []
      for (const sample of Object.values(this.storage.samples)) {
        if (sample.status==this.status&&sample.type!="subplot")
          samplesA.push(sample)
      }
      return samplesA.sort((a, b) => {
        if(a.date < b.date) { return 1; }
        if(a.date > b.date) { return -1; }
        return 0;
      })
    },
  },
  methods: {
    setPopup(state, sample, event) {
      this.popoverOpen = state
      this.popoverData = sample
      this.popoverEvent = event
      if (event)
        event.stopPropagation()
    },
    dateString(date) {
      if (this.status=="In Bearbeitung") {
        return new Date(date).toLocaleString('de-DE', {timeZone: 'Europe/Berlin'})
      }
      else
        return new Date(date).toLocaleString('de-DE', {timeZone: 'Europe/Berlin'})
    },
    deleteItem(id) {
      this.deleteSample(id)
    },
    async submitSample (sample) {
      const loading = this.presentLoading("Funde werden übermittelt")
      let formData = new FormData()
      let body = await this.createSubmission(sample, formData)
      console.log(JSON.stringify(body))
      formData.append('submission', JSON.stringify(body))
      await this.submit(sample, formData)
      loading.then(load => {load.dismiss()})
    },
    async createSubmission (sample, formData) {
      let blob, dataURI, body = {}, smp, wkt, point3857
      if (sample.polyline.length>1&&sample.geoType=="polygon") {
        wkt = "POLYGON(("
        for (const point of sample.polyline) {
          point3857 = proj4('EPSG:4326','EPSG:3857', [point[1], point[0]])
          wkt += point3857[0]+" "+point3857[1]+","
        }
        point3857 = proj4('EPSG:4326','EPSG:3857', [sample.polyline[0][1], sample.polyline[0][0]])
        wkt += point3857[0]+" "+point3857[1]+"))"
      }
      if (sample.polyline.length>1&&sample.geoType=="polyline") {
        wkt = "LINESTRING("
        for (const point of sample.polyline) {
          point3857 = proj4('EPSG:4326','EPSG:3857', [point[1], point[0]])
          wkt += point3857[0]+" "+point3857[1]+","
        }
        wkt = wkt.substring(0, wkt.length-1) + ")"
      }
      smp = {
        external_key: sample.id,
        fields: {
          comment: sample.comment,
          entered_sref: `${sample.yCentroid}, ${sample.xCentroid}`,
          entered_sref_system: 4326,
          sample_method_id: 1,
          geom: wkt
        },
        input_form: "enter-app-record",
        media: [],
        occurrences: [],
        samples: [],
        survey_id: localStorage.getItem('surveyIndicia'),
        type: "samples",
      }
      for (const key of Object.keys(sample.fotos)) {
        smp.media.push({name: key})
        dataURI = await localForage.getItem('img'+key)
        blob = Image.dataURItoBlob(dataURI, 'image/jpeg')
        console.log(key, blob)
        formData.append(key, blob, key + '.jpeg')
      }
      for (const group of this.storage.attributes) {
        for (const attribute of group.attributes) {
          if (attribute.attributeType=='S'&&sample[attribute.attrName]&&!sample[attribute.attrName].length==0) {
            if (attribute.controlType=='hierarchical_select') {
              smp.fields[attribute.attrName] = sample[attribute.attrName][sample[attribute.attrName].length-1]["value"]
            }
            else if (attribute.controlType=='date') {
              smp.fields[attribute.attrName] = sample[attribute.attrName].substring(0, 10)
              smp["created_on"] = sample.date
            }
            else {
              smp.fields[attribute.attrName] = sample[attribute.attrName]
            }
          }
        }
      }
      for (const occurrence of Object.values(sample.occurrences)) {
        let occData = {
          training: true,
          external_key: occurrence.id,
          created_on: sample.date,
          fields: {
            comment: occurrence.comment,
            taxa_taxon_list_id: occurrence.speciesId,
          },
          media: [],
        }
        if (occurrence.sensitivity_precision>0) {
          occData.sensitivity_precision = occurrence.sensitivity_precision
        }
        if (occurrence.zero_abundance=="t") {
          occData.zero_abundance = occurrence.zero_abundance
        }
        let index = smp.occurrences.push(occData)
        for (const key of Object.keys(occurrence.fotos)) {
          smp.occurrences[index-1].media.push({name: key})
          dataURI = await localForage.getItem('img'+key)
          blob = Image.dataURItoBlob(dataURI, 'image/jpeg')
          console.log(key, blob)
          formData.append(key, blob, key + '.jpeg')
        }
        for (const group of this.storage.attributes) {
          for (const attribute of group.attributes) {
            if (attribute.attributeType=='O'&&occurrence[attribute.attrName]) {
              smp.occurrences[index-1].fields[attribute.attrName] = occurrence[attribute.attrName]
            }
          } 
        }
      }
      for (const subSample of sample.samples) {
        smp.samples.push(await this.createSubmission(this.storage.samples[subSample], formData))
      }
      if (sample.type=="subplot")
        return smp
      else {
        body.data = smp
        return body
      }
    },
    async sendClick() {
      if (!navigator.onLine) {
        CONFIG.headerAlert("Keine Internetverbindung", "Bitte später noch einmal versuchen.", [{text:'OK', role: 'ok'}])
      }
      else {
        const confirmation = await CONFIG.headerAlert("Sind alle Funde fertig bearbeitet? Sie können nach Übermittlung nur noch im Webportal verändert werden.", null, [{text:'Abbruch'},{text:'OK',role:'ok'}])
        if (confirmation.role==="ok")
          await this.submitSamples()
      }
    },
    async submitSamples () {
      const loading = this.presentLoading("Funde werden übermittelt")
      let countTotal=0
      for (const sample of Object.values(this.storage.samples)) {
        if(sample.status=="In Bearbeitung"&&sample.type!="subplot") {
          countTotal++
          await this.submitSample(sample)
        }
      }
      loading.then(load => {load.dismiss()})
      this.$router.push({name: 'eingabe'})
      CONFIG.headerAlert(null, this.countTransmit+" von "+countTotal+" Funden übermittelt", [{text:'OK'}])
    },
    async submit(sample, data) {
      await axios.post(CONFIG.api+'/samples', data).then(async response => {
        await this.deleteSampleQuiet(sample)
        this.countTransmit++
      })
      .catch (()=>{})
    },
    refreshClick() {
      if (!navigator.onLine) {
        CONFIG.headerAlert("Keine Internetverbindung", "Bitte später noch einmal versuchen.", [{text:'OK', role: 'ok'}])
      }
      else {
        const loading = this.presentLoading("Meine Funde werden geladen")
        let status = "Meine Funde"
        axios.get(CONFIG.api+'/reports/mobile/occurrences/ffips-filterable_explore_list.xml?occattrs='+this.storage.occAttrs+'&smpattrs='+this.storage.smpAttrs+'&survey_id='+localStorage.getItem('surveyIndicia')+'&my_records=1').then(async response => {        
          this.deleteSamples(status)
          this.initSamples(status, response.data.data)
          loading.then(load => {load.dismiss()})
        })
      }
    },
    async shareSample(sampleObj) {
      let filename
      if (this.storage.globalSettings.shareType == "gpx") {
        this.dataTxt = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
<gpx xmlns="http://www.topografix.com/GPX/1/1" version="1.1" creator="ffips"
  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
  xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">
  <metadata>
    <name>export.gpx</name>
    <desc>FFIpS Funde</desc>
  </metadata>`
        filename = "export.gpx"
      }
      else {
        filename = "export.csv"
        this.csvHeader()
      }
        await this.csvData(sampleObj)
        let dataObj
        if (this.storage.globalSettings.shareType == "gpx") {
          this.dataTxt += `
</gpx>`
          dataObj = new Blob([this.dataTxt], { type: 'text/plain;charset=utf-8' })
        }
        else {
          dataObj = new Blob([ windows1252.encode(this.headerTxt+this.dataTxt)], { type: 'text/plain' })
        }
        this.dataTxt = this.headerTxt = ""
        this.shareFile(filename, dataObj)
    },
    async shareSamples() {
      let filename
      if (this.storage.globalSettings.shareType == "gpx") {
        this.dataTxt = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
<gpx xmlns="http://www.topografix.com/GPX/1/1" version="1.1" creator="ffips"
  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
  xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">
  <metadata>
    <name>export.gpx</name>
    <desc>FFIpS Funde</desc>
  </metadata>`
        filename = "export.gpx"
      }
      else {
        filename = "export.csv"
        this.csvHeader()
      }
      for (const sample of Object.values(this.storage.samples)) {
        if(sample.status=="In Bearbeitung"&&sample.type!="subplot") {
          await this.csvData(sample)
        }
      }
      let dataObj
      if (this.storage.globalSettings.shareType == "gpx") {
        this.dataTxt += `
</gpx>`
        dataObj = new Blob([this.dataTxt], { type: 'text/plain;charset=utf-8' })
      }
      else {
        dataObj = new Blob([ windows1252.encode(this.headerTxt+this.dataTxt)], { type: 'text/plain' })
      }
      this.dataTxt = this.headerTxt = ""
      this.shareFile(filename, dataObj)
      },
  }  
}
</script>
<style scoped>
.round-container {
  box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.15);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  border-radius: 50%;
  background-color: lightgray;
  float: left;
  margin-bottom: .5em;
  margin-right: .5em;
}
.round-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.photo {
  box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.15);
  height: 2.5rem;
  width: 2.5rem;
  float: left;
  margin-bottom: .5em;
  margin-right: .5em;
  background-color: lightgray;
}
.photo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.vuelist-item {
  transition: all 1s;
}
.vuelist-enter, .vuelist-leave-to
{
  opacity: 0;
  transform: translateX(400px);
}
.vuelist-leave-active {
  position: absolute;
  width: 100%;
}
.list-item {
  --padding-start: .5rem;
  --inner-padding-end: .5rem;
}
ion-item-divider {
  --padding-start: .5rem;
}
</style> 